@import "./_Variables.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
    background-color: $background-color;
    color: white;
    font-size: 20px;
    padding-bottom: 50px;
}

.modal {
    color: black;
}

.admin-panel-trigger {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 70px !important;
    cursor: pointer;
}

#admin-panel-action-list {
    border: 1px solid #e5e5e5;
    border-bottom: none;

    div:nth-child(even) {
        background-color: lighten(#e5e5e5, 3%);
    }

    a {
        padding: 10px 15px;
        border-bottom: 1px solid #e5e5e5;
        align-items: center;
        display: flex;

        span {
            font-size: 20px;
        }


        img {
            margin-right: 10px;
        }

        &:hover {
            background-color: #e5e5e5;
        }
    }
}




.large-input {
    width: 600px;
    color: black;
    font-size: 25px;
    max-width: 100%;
}

.medium-input {
    width: 600px;
    color: black;
    max-width: 100%;
}

.dropzone {
    margin: 10px 0px;
    padding: 15px;
    border: 5px white dashed;
    cursor: pointer;

    p {
        font-size: 20px;
    }
}


.preview-image {
    margin: 10px 0px;
    max-width: 600px;
    max-height: 300px;
}

.slick-slider {
    margin: 0px 20px;

    .gallery-image .gallery-thumbnail {
        height: 100px;
    }
}

.gallery-image {
    padding: 10px;
    width: 150px;

    .gallery-thumbnail {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        max-width: 100%;
        height: 150px;
        transition: 0.1s all;

        &:hover {
            transform: scale(1.1);
        }
    }

    p {
        white-space: pre-wrap;
        word-break: break-all;
    }
}

.gallery-grid {
    display: flex;
    flex-wrap: wrap;
}


.custom-button {
    display: inline-block;
    color: white;
    border: 2px solid white;
    padding: 5px 10px;
    border-radius: 20px;
    text-align: center;
    width: 250px;
    cursor: pointer;
    transition: 0.1s all;
    text-decoration: none;
    max-width: 100%;
    background-color: transparent;

    &.red:hover {
        background-color: red;
        border: 2px solid red;
        color: white;
    }

    &:hover {
        text-decoration: none;
        background-color: white;
        color: black;
    }
}

.small-logo {
    font-size: 16px;
    display: inline-block;
    text-align: center;
}

.image-display {
    display: flex;
    justify-content: center;
    flex-direction: column;

    h1 {
        text-align: center;
    }

    img {
        max-height: 60vh;
        max-width: 99vw;
    }
}


.information-table {
    margin-top: 20px;

    td:first-child {
        padding-right: 40px;
    }
}

h2 a {
    color: white;
    &:hover {
        color:white;
    }
}


.button-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 30px 0px;
    padding: 0px;
    flex-wrap: wrap;
    flex-grow: 1;

    li {
        display: flex;
        //flex-grow: 1;
        &:last-child {
            a, span {
                border-right: 2px solid white;
            }
        }

        a, span {
            cursor: pointer;
            border: 2px solid white;
            border-right: none;
            padding: 10px 20px;
            text-decoration: none;
            color: white;
            transition: 0.2s all;

            &.active, &:hover {
                background-color: white;
                color: black;
            }
        }
    }
}

.pages-list {
    margin: 10px 0px;
    display: flex;
    justify-content:center;
    flex-direction: column;

    a {color: white;}

}

.login-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;


    .login-container {
        max-width: 95%;
    }
    p {
        color: #FFFF00;
    }

    input {
        color: black;
        max-width: 100%;
    }
}

.not-found-wrapper {
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        padding: 2rem 0;
    }
}
.error-message {
    background-color: #cc0000;
    padding: 1rem;
}


@media only screen and (max-width: 600px) {
    .button-list {
        flex-direction: column;
        align-items: center;

        li {
            max-width: 100%;

            a, span {
                border-right: 2px solid white;
                margin: 5px 0px;
                width: 400px;
                max-width: 100%;
            }
        }
    }
}
