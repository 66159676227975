
.u-text-center {
    text-align:center;
}

.u-flex {
    display: flex;
}

.u-justify-content {
    justify-content: center;
}

.u-justify-content-space-between {
    justify-content: space-between;
}

.u-align-items-center {
    align-items: center;
}

.u-flex-direction-column {
    flex-direction: column;
}



.u-max-full-width {
    max-width: 100%;
}